import React from "react";
import TICHome from "../Pages/TICHome";
import ManageStudents from "../Pages/ManageStudents";
import AddStudent from "../Pages/AddStudent";
import { Switch, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import RegisterStudents from "../Pages/RegisterStudents";
import StudentArrears from "../Pages/StudentArrears";
import Sports from "../Pages/Sports";
import Clubs from "../Pages/Clubs";
import Departments from "../Pages/Departments";
import StudentContacts from "../Pages/StudentContacts";
import StudentMedicals from "../Pages/StudentMedicals";
import StudentBehaviorAndAttendance from "../Pages/StudentBehaviorAndAttendance";
import EditStudent from "../Pages/EditStudent";
import SettingsPopup from "./SettingsPopup";
import {useDispatch, useSelector} from "react-redux";
import {popupActions} from "../actions/popup.actions";
import CalendarEvents from "../Pages/CalendarEvents";
import ManageCalendar from "../Pages/ManageCalendar";
import ManageNotifications from "../Pages/ManageNotifications";
import Profile from "../Pages/Profile";
import StudentResults from "../Pages/StudentResults";
import ClassStudents from "../Pages/ClassStudents";
import PortalUsers from "../Pages/PortalUsers";
import AddPortalUser from "../Pages/AddPortalUser";
import ManageTeachers from "../Pages/ManageTeachers";
import AddTeacher from "../Pages/AddTeacher";
import EditTeacher from "../Pages/EditTeacher";
import MarkingSchema from "../Pages/MarkingSchema";
import Registration from "../Pages/Registration";

 

function Main() {
    const settingsPopup = useSelector(
        (state) => state.popupReducer.settingsPopup
    );
    const dispatch = useDispatch();



  return (
    <React.Fragment>

        <SettingsPopup
            show={settingsPopup}
            onHide={() =>  { document.getElementsByClassName("modal-backdrop")[0].hidden = true;
                            dispatch(popupActions.clear()
                            )}}
        />
    <Navbar />   {/*top title bar */}
   <Sidebar />   {/*   left side bar */}
       {/* main interchangeble window below */}
      <Switch>
         <Route exact path="/home/Marks" component={TICHome} />
          <Route exact path="/home/StudentResults" component={StudentResults} />
          <Route exact path="/home/ClassStudents" component={ClassStudents} />

          <Route  exact path="/home" component={Profile} />
          <Route  exact path="/home/ManageStudents" component={ManageStudents} />
          <Route  exact path="/home/addStudent" component={AddStudent} />
          <Route  exact path="/home/addTeacher" component={AddTeacher} />
          <Route  exact path="/home/editTeacher" component={EditTeacher} />
          <Route  exact path="/home/SportMarks" component={Sports} />
          <Route  exact path="/home/ClubMarks" component={Clubs} />
          <Route  exact path="/home/Departments" component={Departments} />
          <Route  exact path="/home/Contacts" component={StudentContacts} />
          <Route  exact path="/home/Medicals" component={StudentMedicals} />
          <Route  exact path="/home/StudentBehaviorAndAttendance" component={StudentBehaviorAndAttendance} />
          <Route  exact path="/home/StudentArrears" component={StudentArrears} /> x          <Route  exact path="/home/registerStudents" component={RegisterStudents} />
          <Route  exact path="/home/EditStudent" component={EditStudent} />
          <Route  exact path="/home/Calendar" component={CalendarEvents} />
          <Route  exact path="/home/ManageCalendar" component={ManageCalendar} />
          <Route  exact path="/home/ManageNotifications" component={ManageNotifications} />
          <Route exact path="/home/admin/PortalUsers" component={PortalUsers} />
          <Route exact path="/home/admin/AddUser" component={AddPortalUser} />
          <Route exact path="/home/ManageTeachers" component={ManageTeachers} />
          <Route exact path="/home/MarkingScheme" component={MarkingSchema} />
          <Route exact path="/home/Registration" component={Registration} />


      </Switch>
    </React.Fragment>
  );
}
export default Main;
