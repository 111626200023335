import {
   teacherConstants
  } from "../constants/teacher.constants";

  const initialState = {
      Marks:[],
      CurrentClass:" "
  };

  
  export function teacherReducer(state =initialState, action) {
    switch (action.type) {
        case teacherConstants.RESET_NET_ERROR:
            return {
                ...state,
                netError: false
            };
        case teacherConstants.PDF_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingPDF: true,
                fetchedPDF:false,
                loading:true

            };
        case teacherConstants.PDF_RETRIEVE_FAILURE:
            return{
                ...state,
                fetchingPDF:false,
                fetchedPDF:false,
                netError:true,
                error: action.error,
                loading:false,

            }
        case teacherConstants.PDF_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingPDF: false,
                netError:false,
                fetchedPDF:true,


            };
        case teacherConstants.STUDENT_ID_RESET_REQUEST:
            return {
                ...state,
                loading:false,
                validStudent:"",
                validatedStudent:false,
                CurrentStudent: ""
            };

        case teacherConstants.TEACHER_ID_RESET_REQUEST:
            return {
                ...state,
                loading:false,
                validTeacher:"",
                validatedTeacher:false,
                CurrentTeacher: ""
            };

        case teacherConstants.MINI_PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingMiniProfile: true,
                fetchedMiniProfile:false

            };
        case teacherConstants.MINI_PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingMiniProfile:false,
                fetchedMiniProfile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MINI_PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingMiniProfile: false,
                netError:false,
                fetchedMiniProfile:true,
                CurrentStudent:action.payload,

            };

        case teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingTeacherMiniProfile: true,
                fetchedTeacherMiniProfile:false

            };
        case teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingTeacherMiniProfile:false,
                fetchedTeacherMiniProfile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingTeacherMiniProfile: false,
                netError:false,
                fetchedTeacherMiniProfile:true,
                CurrentTeacher:action.payload,

            };

        case teacherConstants.PROFILE_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingProfile: true,
                fetchedProfile:false

            };
        case teacherConstants.PROFILE_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingProfile:false,
                fetchedProfile:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.PROFILE_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingProfile: false,
                netError:false,
                fetchedProfile:true,
                Profile:action.payload.isReportGenerated?{...action.payload,ReportCard:JSON.parse(action.payload.ReportCard)}:action.payload,
                CurrentClass:action.payload.CurrentClass?action.payload.CurrentClass:""
            };
  
      case teacherConstants.TEACHER_RETRIEVE_REQUEST:
        return {
            ...state,
            loading:true,
          fetchingTic: true,
          fetchedTIC:false
        
        };
        case teacherConstants.TEACHER_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingTic:false,
                fetchedTIC:false,
                netError:true,
                error: action.error
            }

      case teacherConstants.TEACHER_RETRIEVE_SUCCESS:
        return {
            ...state,
            loading:false,
          fetchingTic: false,
            netError:false,
          fetchedTIC:true,
          TIC :action.TIC,
        };


        case teacherConstants.MARKS_RETRIEVE_REQUEST:
          return {
              ...state,
              loading:true,
            fetchingMarks: true,
            fetchedMarks:false
          
          };
          case teacherConstants.MARKS_RETRIEVE_FAILURE:
              return{
                  ...state,
                  loading:false,
                  fetchingMarks:false,
                  Marks: [],

                  netError:true,
                  fetchedMarks:false,
                  error: action.error
              }

        case teacherConstants.MARKS_RETRIEVE_SUCCESS:
          return {
              ...state,
              loading:false,
            fetchingMarks: false,
            fetchedMarks:true,
              netError:false,
              MarksCLass:action.marks.classCode+"",
            Marks :{...action.marks,marks:action.marks.marks.sort((prev,next)=>(prev.id>next.id)?1:-1)}
          };


        case teacherConstants.MARKS_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,

                submittingMarks: true,
                submittedMarks:false

            };
        case teacherConstants.MARKS_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingMarks:false,
                submittedMarks:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.MARKS_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingMarks: false,
                netError:false,
                submittedMarks:true,

            };
        case teacherConstants.OPTIONS_REQUEST:
            return {
                ...state,
                loading:true,
                RequestingOptions: true,
                RequestedOptions:false

            };
        case teacherConstants.OPTIONS_FAILURE:
            return{
                ...state,
                loading:false,
                RequestingOptions:false,
                RequestedOptions:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.OPTIONS_SUCCESS:
            return {
                ...state,
                loading:false,
               RequestingOptions: false,
               RequestedOptions:true,
                netError:false,
                options: action.payload


            };
        case teacherConstants.TEACHER_ADD_REQUEST:
            return {
                ...state,
                loading:true,
                addingTeacher: true,
                addedTeacher:false

            };
        case teacherConstants.TEACHER_ADD_FAILURE:
            return{
                ...state,
                loading:false,
                addingTeacher:false,
                addedTeacher:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHER_ADD_SUCCESS:
            return {
                ...state,
                loading:false,
                addingTeacher: false,
                netError:false,
                addedTeacher:true,

            };
        case teacherConstants.TEACHER_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                editingTeacher: true,
                editedTeacher:false

            };
        case teacherConstants.TEACHER_EDIT_FAILURE:
            return{
                ...state,
                loading:false,
                editingTeacher:false,
                editedTeacher:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHER_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                editingTeacher: false,
                netError:false,
                editedTeacher:true,

            };
        case teacherConstants.TEACHER_REMOVE_REQUEST:
            return {
                ...state,
                loading:true,
                removingTeacher: true,
                removedTeacher:false

            };
        case teacherConstants.TEACHER_REMOVE_FAILURE:
            return{
                ...state,
                loading:false,
                removingTeacher:false,
                removedTeacher:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHER_REMOVE_SUCCESS:
            return {
                ...state,
                loading:false,
                removingTeacher: false,
                netError:false,
                removedTeacher:true,

            };
        case teacherConstants.STUDENT_ADD_REQUEST:
            return {
                ...state,
                loading:true,
                addingStudent: true,
                addedStudent:false

            };
        case teacherConstants.STUDENT_ADD_FAILURE:
            return{
                ...state,
                loading:false,
                addingStudent:false,
                addedStudent:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.STUDENT_ADD_SUCCESS:
            return {
                ...state,
                loading:false,
                addingStudent: false,
                netError:false,
                addedStudent:true,

            };
        case teacherConstants.STUDENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudents: true,
                fetchedStudents:false

            };
        case teacherConstants.STUDENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
               fetchingStudents:false,
                fetchedStudents:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.STUDENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                students:action.payload

            };
        case teacherConstants.TEACHERS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingTeachers: true,
                fetchedTeachers:false

            };
        case teacherConstants.TEACHERS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingTeachers:false,
                fetchedTeachers:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.TEACHERS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingTeachers: false,
                fetchedTeachers:true,
                netError:false,
                teachers:action.payload

            };

        case teacherConstants.ALTER_STUDENTS:
            return {
                ...state,

                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                UnregisteredStudents:action.payload.sort((prev,next)=>(prev.id>next.id)?1:-1)

            };
        case teacherConstants.ALTER_TEACHER_SUBJECTS:
            return {
                ...state,

                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                CurrentTeacher:{...state.CurrentTeacher,subjects:action.payload}

            };

        case teacherConstants.REGISTERED_STUDENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudents: true,
                fetchedStudents:false

            };
        case teacherConstants.REGISTERED_STUDENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingStudents:false,
                netError:true,
                fetchedStudents:false,
                error: action.error
            }

        case teacherConstants.REGISTERED_STUDENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudents: false,
                fetchedStudents: true,
                netError:false,
                registeredStudents: action.payload.sort((prev,next)=>(prev.id>next.id)?1:-1)
            };

        case teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudents: true,
                fetchedStudents:false

            };
        case teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingStudents:false,
                fetchedStudents:false,
                netError:true,
                error: action.error
            }

        case teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudents: false,
                fetchedStudents:true,
                netError:false,
                UnregisteredStudents:action.payload.sort((prev,next)=>(prev.id>next.id)?1:-1)

            };
        case teacherConstants.STUDENT_UPLOAD_REQUEST:
            return {
                ...state,
                loading:true,
                uploadingStudents: true,

                uploadedStudents:false

            };
        case teacherConstants.STUDENT_UPLOAD_FAILURE:
            return{
                ...state,
                loading:false,
                uploadingStudents:false,
                uploadedStudents:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_UPLOAD_SUCCESS:
            return {
                ...state,
                loading:false,
                uploadingStudents: false,
                netError:false,
                uploadedStudents:true,

            };
        case teacherConstants.REGISTER_STUDENT_REQUEST:
            return {
                ...state,
                loading:true,
                registeringStudents: true,

                registeredStudents:false

            };
        case teacherConstants.REGISTER_STUDENT_FAILURE:
            return{
                ...state,
                loading:false,
                registeringStudents:false,
                registeredStudents:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.REGISTER_STUDENT_SUCCESS:
            return {
                ...state,
                loading:false,
                registeringStudent: false,
                netError:false,
                registeredStudent:true,

            };

        case teacherConstants.STUDENT_VALIDATE_REQUEST:
            return {
                ...state,
                loading:true,
                validatingStudent: true,

                validatedStudent:false

            };
        case teacherConstants.STUDENT_VALIDATE_FAILURE:
            return{
                ...state,
                loading:false,
                validatingStudent:false,
                validatedStudent:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_VALIDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                validatingStudent: false,
                netError:false,
                validatedStudent:true,
                validStudent:action.payload

            };


        case teacherConstants.TEACHER_VALIDATE_REQUEST:
            return {
                ...state,
                loading:true,
                validatingTeacher: true,

                validatedTeacher:false

            };
        case teacherConstants.TEACHER_VALIDATE_FAILURE:
            return{
                ...state,
                loading:false,
                validatingTeacher:false,
                validatedTeacher:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.TEACHER_VALIDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                validatingTeacher: false,
                netError:false,
                validatedTeacher:true,
                validTeacher:action.payload

            };

        case teacherConstants.ALL_ARREARS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingArrears: true,
                fetchedArrears:false

            };
        case teacherConstants.ALL_ARREARS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingArrears:false,
                fetchedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.ALL_ARREARS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingArrears: false,
                netError:false,
                fetchedArrears:true,
                studentsArrears:action.payload

            };

        case teacherConstants.ARREARS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingArrears: true,
                fetchedArrears:false

            };
        case teacherConstants.ARREARS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingArrears:false,
                fetchedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.ARREARS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingArrears: false,
                netError:false,
                fetchedArrears:true,
                studentArrears:action.payload

            };

        case teacherConstants.STUDENT_ARREAR_ADD_REQUEST:
            return {
                ...state,
                loading:true,
                addingArrears: true,
                addedArrears:false

            };
        case teacherConstants.STUDENT_ARREAR_ADD_FAILURE:
            return{
                ...state,
                loading:false,
                addingArrears:false,
                addedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_ARREAR_ADD_SUCCESS:
            return {
                ...state,
                loading:false,
                addingArrears: false,
                netError:false,
                addedArrears:true,
                validatedStudent:false,
                validStudent:""

            };

        case teacherConstants.STUDENT_ARREAR_CLEAR_REQUEST:
            return {
                ...state,
                loading:true,
                clearingArrears: true,
                clearedArrears:false

            };
        case teacherConstants.STUDENT_ARREAR_CLEAR_FAILURE:
            return{
                ...state,
                loading:false,
                clearingArrears:false,
                clearedArrears:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_ARREAR_CLEAR_SUCCESS:
            return {
                ...state,
                loading:false,
                clearingArrears: false,
                netError:false,
                clearedArrears:true,

            };

        case teacherConstants.SPORT_MARKS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingSportMarks: true,

                fetchedSportMarks:false

            };
        case teacherConstants.SPORT_MARKS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingSportMarks:false,
                fetchedSportMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_MARKS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingSportMarks: false,
                netError:false,
                fetchedSportMarks:true,
                SportMarks:action.payload

            };

        case teacherConstants.SPORT_MARKS_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingSportMarks: true,

                submittedSportMarks:false

            };
        case teacherConstants.SPORT_MARKS_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingSportMarks:false,
                submittedSportMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.SPORT_MARKS_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingSportMarks: false,
                netError:false,
                submittedSportMarks:true,

            };
        case teacherConstants.CLUB_MARKS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingClubMarks: true,

                fetchedClubMarks:false

            };
        case teacherConstants.CLUB_MARKS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingClubMarks:false,
                fetchedClubMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_MARKS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingClubMarks: false,
                netError:false,
                fetchedClubMarks:true,
                ClubMarks:action.payload

            };

        case teacherConstants.CLUB_MARKS_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingClubMarks: true,

                submittedClubMarks:false

            };
        case teacherConstants.CLUB_MARKS_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingClubMarks:false,
                submittedClubMarks:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CLUB_MARKS_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingClubMarks: false,
                netError:false,
                submittedClubMarks:true,

            };

        case teacherConstants.DEPARTMENT_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingDepartments: true,

                fetchedDepartments:false

            };
        case teacherConstants.DEPARTMENT_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingDepartments:false,
                fetchedDepartments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingDepartments: false,
                netError:false,
                fetchedDepartments:true,
                Departments:action.payload

            };


        case teacherConstants.DEPARTMENT_CLEAR_REQUEST:
            return {
                ...state,
                loading:true,
                clearingDepartments: true,

                clearedDepartments:false

            };
        case teacherConstants.DEPARTMENT_CLEAR_FAILURE:
            return{
                ...state,
                loading:false,
                clearingDepartments:false,
                clearedDepartments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.DEPARTMENT_CLEAR_SUCCESS:
            return {
                ...state,
                loading:false,
                clearingDepartments: false,
                netError:false,
                clearedDepartments:true,

            }

        case teacherConstants.CONTACT_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                updatingContact: true,

                updatedContact:false

            };
        case teacherConstants.CONTACT_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                updatingContact:false,
                updatedContact:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CONTACT_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                updatingContact: false,
                netError:false,
                updatedContact:true,
                validatedStudent:false,
                validStudent:""

            }

        case teacherConstants.CONTACTS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingContacts: true,

                fetchedContacts:false

            };
        case teacherConstants.CONTACTS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingContacts:false,
                fetchedContacts:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CONTACTS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingContacts: false,
                netError:false,
                fetchedContacts:true,
                Contacts:action.payload

            };

        case teacherConstants.MEDICALS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingMedicals: true,

                fetchedMedicals:false

            };
        case teacherConstants.MEDICALS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingMedicals:false,
                fetchedMedicals:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MEDICALS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingMedicals: false,
                netError:false,
                fetchedMedicals:true,
                Medicals:action.payload

            };

        case teacherConstants.MEDICALS_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                updatingMedicals: true,

                updatedMedicals:false

            };
        case teacherConstants.MEDICALS_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                updatingMedicals:false,
                updatedMedicals:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.MEDICALS_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                updatingMedicals: false,
                netError:false,
                updatedMedicals:true,
                validatedStudent:false,
                validStudent:""
            };

        case teacherConstants.STUDENTS_COMMENTS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingStudComments: true,

                fetchedStudComments:false

            };
        case teacherConstants.STUDENTS_COMMENTS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingStudComments:false,
                fetchedStudComments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENTS_COMMENTS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingStudComments: false,
                netError:false,
                fetchedStudComments:true,
                StudentComments:action.payload

            };

        case teacherConstants.STUDENT_COMMENT_SUBMIT_REQUEST:
            return {
                ...state,
                loading:true,
                submittingStudComments: true,
                submittedStudComments:false

            };
        case teacherConstants.STUDENT_COMMENT_SUBMIT_FAILURE:
            return{
                ...state,
                loading:false,
                submittingStudComments:false,
                submittedStudComments:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_COMMENT_SUBMIT_SUCCESS:
            return {
                ...state,
                loading:false,
                submittingStudComments: false,
                netError:false,
                submittedStudComments:true,
            };

        case teacherConstants.NOTIFICATIONS_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingNotifications: true,

                fetchedNotifications:false

            };
        case teacherConstants.NOTIFICATIONS_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingNotifications:false,
                fetchedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingNotifications: false,
                netError:false,
                fetchedNotifications:true,
                Notifications:action.payload

            };

        case teacherConstants.NOTIFICATIONS_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingNotifications: true,
                UpdatedNotifications:false

            };
        case teacherConstants.NOTIFICATIONS_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingNotifications:false,
                UpdatedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingNotifications: false,
                netError:false,
                UpdatedNotifications:true,
            };

        case teacherConstants.NOTIFICATIONS_DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingNotifications: true,
                UpdatedNotifications:false

            };
        case teacherConstants.NOTIFICATIONS_DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingNotifications:false,
                UpdatedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingNotifications: false,
                netError:false,
                UpdatedNotifications:true,
            };
        case teacherConstants.NOTIFICATIONS_SAVE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingNotifications: true,
                UpdatedNotifications:false
            };
        case teacherConstants.NOTIFICATIONS_SAVE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingNotifications:false,
                UpdatedNotifications:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.NOTIFICATIONS_SAVE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingNotifications: false,
                netError:false,
                UpdatedNotifications:true,

            };

        case teacherConstants.CALENDAR_RETRIEVE_REQUEST:
            return {
                ...state,
                loading:true,
                fetchingCalendar: true,

                fetchedCalendar:false

            };
        case teacherConstants.CALENDAR_RETRIEVE_FAILURE:
            return{
                ...state,
                loading:false,
                fetchingCalender:false,
                fetchingCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_RETRIEVE_SUCCESS:
            return {
                ...state,
                loading:false,
                fetchingCalendar: false,
                netError:false,
                fetchedCalendar:true,
                Calendar:action.payload

            };

        case teacherConstants.CALENDAR_UPDATE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingCalendar: true,
                UpdatedCalendar:false

            };
        case teacherConstants.CALENDAR_UPDATE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingCalender:false,
                UpdatedCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_UPDATE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingCalendar: false,
                netError:false,
                UpdatedCalendar:true,
            };

        case teacherConstants.CALENDAR_DELETE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingCalendar: true,
                UpdatedCalendar:false

            };
        case teacherConstants.CALENDAR_DELETE_FAILURE:
            return{
                ...state,
                loading:false,
                UpdatingCalender:false,
                UpdatedCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_DELETE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingCalendar: false,
                netError:false,
                UpdatedCalendar:true,
            };
        case teacherConstants.CALENDAR_SAVE_REQUEST:
            return {
                ...state,
                loading:true,
                UpdatingCalendar: true,
                UpdatedCalendar:false

            };
        case teacherConstants.CALENDAR_SAVE_FAILURE:
            return{
                ...state,
                loading:false,

                UpdatingCalender:false,
                UpdatedCalendar:false,
                netError:true,
                error: action.error
            }
        case teacherConstants.CALENDAR_SAVE_SUCCESS:
            return {
                ...state,
                loading:false,
                UpdatingCalendar: false,
                netError:false,
                UpdatedCalendar:true,

            };
        case teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST:
            return {
                ...state,
                loading:true,
                EditingStudentReg: true,
                EditedStudentReg:false

            };
        case teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE:
            return{
                ...state,
                loading:false,

                EditingStudentReg:false,
                EditedStudentReg:false,

                netError:true,
                error: action.error
            }
        case teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS:
            return {
                ...state,
                loading:false,
                EditingStudentReg: false,
                netError:false,
                EditedStudentReg:true,

            };



      default:
        return state
    }
  }