
import "../css/dash.css";

import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";
import {ContextMenu, MenuItem, ContextMenuTrigger, SubMenu} from "react-contextmenu";

import {alertActions} from '../actions';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Link} from "react-router-dom";
import { Collapse, Badge } from 'reactstrap';
import Footer from "../Components /Footer";


function  Registration() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const [isSubjectOpen, setIsSubjectOpen] = useState(false);
    const toggleSubject = () => setIsSubjectOpen(!isSubjectOpen);
    const [isClubOpen, setIsClubOpen] = useState(false);
    const toggleClub = () => setIsClubOpen(!isClubOpen);
    const [isSportOpen, setIsSportOpen] = useState(false);
    const toggleSport = () => setIsSportOpen(!isSportOpen);

    const dispatch = useDispatch();
    const tic = useSelector((state) => state.teacherReducer.TIC);
    const options = useSelector((state) => state.teacherReducer.options);
    const uploadedStudents = useSelector((state) => state.teacherReducer.fetchedStudents);
    const netError = useSelector((state) => state.teacherReducer.netError);
    const user = useSelector((state)=>state.authenticationReducer.user)
    const studentOptions= useSelector((state)=>state.teacherReducer.options)
    const message = useSelector((state) => state.alert);
    const students = useSelector((state) => state.teacherReducer.UnregisteredStudents);

    React.useEffect(() => {
        if(!students) {
            dispatch(teacherActions.getClassRegistrationbyTic())
        }
        if(!options && !netError) {
            dispatch(teacherActions.getOptions())
        }
        dispatch(pagesActions.updatepage(pagesConstants.REGISTRATION));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])

    const columns = [
        {
            title: "Student Id",
            field:"id" ,
        },
        {
            title:"Last Name" ,
            field: "lname",
            // hidden:true
        },
        {
            title: "First Name",
            field:"fname" ,
        },
        {
            title:"Date Of Birth" ,
            field:"dob" ,
        },{
            title: "Gender",
            field: "sex",
        }
    ];







    return (
        <>
            <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                <div className={"block-header "}>
                    <div className={"row clearfix  align-items-end  "}>
                        <div className={"col-4 text-bold  "}>
                            <strong><h2>Student Registration </h2></strong>
                        </div>
                    </div>
                </div>
                { message ? (
                    <div className={message.type + " my-1"}>
                        {" "}
                        <div className="popup"> {message.message}</div>
                    </div>) : ("")
                }
                {/*Breadcrumb*/}
                <nav aria-label="breadcrumb" className="main-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/home">
                            Home
                        </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Register Students</li>
                    </ol>
                </nav>
                {/*Breadcrumb*/}



                <div className="row mt-5 p-3">
                    <div className="col border p-0">
                        {students? <MaterialTable

                            title= {students[0].clazz?students[0].clazz:"No Students Uploaded For Registration"}
                            icons={tableIcons}
                            data={students}

                            columns={columns}
                            options={{ selection: false,search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]

                            }}


                           detailPanel={[
                               {render: rowData => {

                                    return (
                                        <div className={'container'}
                                            style={{

                                                textAlign: 'center',

                                                borderTop:'3px solid #ffc107'
                                            }}
                                        >
                                            <h5 className="row ml-5 pl-5 py-2 pt-4">{rowData.lname+" "+rowData.fname}</h5>
                                            <div className="row ml-3 py-2">
                                                <div className="col-4">
                                                    <strong className="text-bold mr-3">HOUSE</strong>

                                                <select name="houseId" value={rowData.houseId}  disabled >

                                                    {options.houses.map(house=>{

                                                        return(
                                                            <>
                                                                <option value={house.houseId} >{house.house}</option>
                                                            </>
                                                        )})}

                                                </select >
                                                </div>
                                            </div>
                                            <div className="row mx-5  py-2" >
                                                <div className="col">
                                                    <div className="col  bg-light border pl-5 rounded mx-2 ">
                                                    <div className="row mini-box"><strong>SUBJECTS </strong></div>
                                                    {rowData.Subjects!==undefined?(
                                                        rowData.Subjects.map( subject=>{
                                                            return(
                                                                <>
                                                                <div className="row mini-box"><div  className=" m-0 p-0">
                                                                        <div> {subject}</div>
                                                                </div>
                                                                </div>
                                                                </>
                                                            )})):""
                                                    }

                                        </div>
                                                </div>
                                                <div className="col">
                                                    <div className="col  bg-light border pl-5 rounded  mx-2"  >

                                                    <div className="row mini-box"><strong>SPORTS </strong></div>
                                                    {rowData.Sports!==undefined?(
                                                        rowData.Sports.map( subject=>{
                                                            return(
                                                                <>
                                                                <div className="row mini-box "><div  className=" m-0 p-0">
                                                                        <div className= {subject}  style={{cursor:'pointer'}}> {subject}</div>
                                                                </div>
                                                                </div>
                                                                </>
                                                            )})):""
                                                    }
                                                </div>
                                                </div>
                                                <div className="col">
                                                    <div className="col  bg-light border pl-5 rounded  mx-2"   >
                                                        <div className="row mini-box"><strong>CLUBS </strong></div>
                                                        {rowData.Clubs!==undefined?(
                                                            rowData.Clubs.map( subject=>{

                                                                return(
                                                                    <>
                                                                        <div className="row mini-box "><div  className=" m-0 p-0">
                                                                                <div className= {subject}  style={{cursor:'pointer'}}> {subject}</div>
                                                                        </div>
                                                                        </div>
                                                                    </>
                                                                )})):""
                                                        }
                                                </div>
                                                </div>
                                            </div>

                                            <br/><br/><br/>

                                        </div>
                                      )
                                }
                            }]}


                        />:<MaterialTable
                            title= "Student Registration"
                            icons={tableIcons}
                            data={[]}
                            columns={columns}
                            options={{ selection: false,search: true, paging: true, filtering: false, exportButton: true,grouping: false, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}

                        />
                        }
                    </div>



                </div>
            <br/>

            <Footer/>

            </div>
        </>);

}

export default Registration;


