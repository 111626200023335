import {teacherConstants} from "../constants/teacher.constants";
import {teacherService} from "../services/teacher.service";
import {alertActions} from './index';
import {useSelector} from "react-redux";
import {history} from "../shared/history";


export const teacherActions = {
    genReports,
    getTeacher,
    getSubjectMarks,
    removeStudentSubjectByAdmin,
    submitMarks,
    uploadStudents,
    getSportMarks,
    getClubMarks,
    submitMark,
    submitClassTeacherComments,
    submitSportMark,
    submitClubMark,
    getOptions,
    getClassOptions,
    saveStudent,
    saveTeacher,
    editTeacher,
    updateStudent,
    getStudents,
    getTeachers,
    getClassStudents,
    getUnregisteredStudents,
    getRegisteredStudents,
    getStudentArrears,
    getClassArrears,
    validateId,
    validateTeacherId,
    alterStudents,
    RegisterStudents,
    CreateArrear,
    ClearStudent,
    getDepartmentStats,
    clearDepartment,
    getStudentContacts,
    CreateContact,
    ClearContact,
    getStudentMedicals,
    CreateMedicalCondition,
    getStudentComments,
    getCalendar,
    RemoveCalendarEvent,
    UpdateCalendar,
    getNotifications,
    UpdateNotifications,
    RemoveNotification,
    getProfile,
    resetId,
    resetTeacherId,
    getMiniProfile,
    retrievePdfResults,
    downloadClearances,
    getPDFClassLists,
    getPDFCalendar,
    removeStudentSubject,
    removeStudentSport,
    removeStudentClub,
    AddSubject,
    AddSport,
    AddClub,
    editTeacherSubjects,
    getClassRegistration,
    getClassRegistrationbyTic,
    AddSubjectByAdmin,
    resetNetError,
    downloadMarkTemplate



};

function resetNetError() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.RESET_NET_ERROR}
    }
}
function AddClub(studentId,club) {
    return dispatch => {
        dispatch(request());

        teacherService.AddClub(studentId,club)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function AddSport(studentId,sport) {
    return dispatch => {
        dispatch(request());

        teacherService.AddSport(studentId,sport)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}

function AddSubjectByAdmin(studentId,subject,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.AddSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getSubjectMarks(subject,classKey));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function AddSubject(studentId,subject) {
    return dispatch => {
        dispatch(request());

        teacherService.AddSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function removeStudentClub(studentId,club) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentClub(studentId,club)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function removeStudentSport(studentId,sport) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentSport(studentId,sport)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}
function  removeStudentSubject(studentId,subject) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getMiniProfile(studentId));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}

function removeStudentSubjectByAdmin(studentId,subject,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.removeStudentSubject(studentId,subject)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getSubjectMarks(subject,classKey));
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_REGISTRATION_EDIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_REGISTRATION_EDIT_FAILURE, error}
    }
}


function getPDFCalendar() {
    return dispatch => {
        dispatch(request());

        teacherService.getPDFCalendar()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}

function getPDFClassLists(classes) {
    return dispatch => {
        dispatch(request());

        teacherService.getPDFClassLists(classes)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}

function downloadMarkTemplate(Subject ,SubjectClass) {
    return dispatch => {
        dispatch(request());

        teacherService.downloadMarkTemplate(Subject ,SubjectClass)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}


function downloadClearances() {
    return dispatch => {
        dispatch(request());

        teacherService.downloadClearances()
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}


function retrievePdfResults(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.retrievePdfResults(ID)
            .then(
                res=> {
                    downloadEncodedFile(res.file, res.fileName)
                    dispatch(success());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PDF_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PDF_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PDF_RETRIEVE_FAILURE, error}
    }
}


function resetId() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.STUDENT_ID_RESET_REQUEST}
    }
}

function resetTeacherId() {
    return dispatch => {
        dispatch(request());
    };
    function request() {
        return {type:teacherConstants.TEACHER_ID_RESET_REQUEST}
    }
}

function getTeacherMiniProfile(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.getTeacherMiniProfile(ID)
            .then(
                profile=> {
                    dispatch(success(profile));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_MINI_PROFILE_RETRIEVE_FAILURE, error}
    }
}


function getMiniProfile(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.getMiniProfile(ID)
            .then(
                profile=> {
                    dispatch(success(profile));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MINI_PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MINI_PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MINI_PROFILE_RETRIEVE_FAILURE, error}
    }
}

function getProfile() {
    return dispatch => {
        dispatch(request());

        teacherService.getProfile()
            .then(
                profile=> {
                    dispatch(success(profile));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.PROFILE_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.PROFILE_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.PROFILE_RETRIEVE_FAILURE, error}
    }
}

function RemoveNotification(notificationId) {
    return dispatch => {
        dispatch(request());

        teacherService.RemoveNotification(notificationId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getNotifications());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NOTIFICATIONS_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.NOTIFICATIONS_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.NOTIFICATIONS_DELETE_FAILURE, error}
    }
}
function UpdateNotifications(notification) {
    return dispatch => {
        dispatch(request());

        teacherService.UpdateNotifications(notification)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getNotifications());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NOTIFICATIONS_UPDATE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.NOTIFICATIONS_UPDATE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.NOTIFICATIONS_UPDATE_FAILURE, error}
    }
}
function getNotifications() {
    return dispatch => {
        dispatch(request());

        teacherService.getNotifications()
            .then(
                notifications=> {
                    dispatch(success(notifications));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.NOTIFICATIONS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.NOTIFICATIONS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.NOTIFICATIONS_RETRIEVE_FAILURE, error}
    }
}


function UpdateCalendar(calEvent) {
    return dispatch => {
        dispatch(request());

        teacherService.UpdateCalendar(calEvent)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getCalendar());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CALENDAR_UPDATE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CALENDAR_UPDATE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CALENDAR_UPDATE_FAILURE, error}
    }
}

function RemoveCalendarEvent(eventId) {
    return dispatch => {
        dispatch(request());

        teacherService.RemoveCalendarEvent(eventId)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(getCalendar());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CALENDAR_DELETE_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CALENDAR_DELETE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CALENDAR_DELETE_FAILURE, error}
    }
}
function getCalendar() {
    return dispatch => {
        dispatch(request());

        teacherService.getCalendar()
            .then(
                events=> {
                    dispatch(success(events));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CALENDAR_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CALENDAR_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CALENDAR_RETRIEVE_FAILURE, error}
    }
}

function getStudentComments(user) {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentComments(user)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENTS_COMMENTS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENTS_COMMENTS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENTS_COMMENTS_RETRIEVE_FAILURE, error}
    }
}


function ClearContact(contact) {
    return dispatch => {
        dispatch(request());

        teacherService.ClearContact(contact)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentContacts());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CONTACT_DELETE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CONTACT_DELETE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CONTACT_DELETE_FAILURE, error}
    }
}


function CreateMedicalCondition(condition) {
    return dispatch => {
        dispatch(request());

        teacherService.CreateMedicalCondition(condition)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentMedicals());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };  function request() {
        return {type:teacherConstants.MEDICALS_UPDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MEDICALS_UPDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MEDICALS_UPDATE_FAILURE, error}
    }
}

function CreateContact(contact) {
    return dispatch => {
        dispatch(request());

        teacherService.CreateContact(contact)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentContacts());
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CONTACT_UPDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CONTACT_UPDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CONTACT_UPDATE_FAILURE, error}
    }
}


function getStudentMedicals() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentMedicals()
            .then(
                contacts=> {
                    dispatch(success(contacts));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MEDICALS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.MEDICALS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.MEDICALS_RETRIEVE_FAILURE, error}
    }
}

function getStudentContacts() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentContacts()
            .then(
                contacts=> {
                    dispatch(success(contacts));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CONTACTS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CONTACTS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CONTACTS_RETRIEVE_FAILURE, error}
    }
}
function genReports() {
    return dispatch => {
        dispatch(request());

        teacherService.genReports()
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));

                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REPORT_GEN_REQUEST}
    }

    function success() {
        return {type: teacherConstants.REPORT_GEN_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.REPORT_GEN_FAILURE,error}
    }
}

function  clearDepartment(dptId,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.clearDepartment(dptId)
            .then(
                response=> {
                    dispatch(success(response));
                    dispatch(alertActions.success("Department successfully Submitted!"));
                    dispatch(getDepartmentStats(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_CLEAR_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.DEPARTMENT_CLEAR_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_CLEAR_FAILURE,error}
    }
}

function  ClearStudent(clrId,tic) {
        return dispatch => {
            dispatch(request());

            teacherService.ClearStudent(clrId)
                .then(
                    response=> {
                        dispatch(success());
                        dispatch(alertActions.success(response.Message));
                        dispatch(teacherActions.getClassArrears(tic))
                        setTimeout(()=>{dispatch(alertActions.clear())},5000)

                    },
                    error => {
                        console.log(error.Message)
                        if(error.Message){
                            dispatch(failure(error.Messsage));
                            dispatch(alertActions.error(error.Message));
                        }else{
                            dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                            dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                        }

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                );
        };

        function request() {
            return {type:teacherConstants.STUDENT_ARREAR_CLEAR_REQUEST}
        }

        function success() {
            return {type: teacherConstants.STUDENT_ARREAR_CLEAR_SUCCESS}
        }

        function failure(error) {
            return {type: teacherConstants.STUDENT_ARREAR_CLEAR_FAILURE,error}
        }
    }
function CreateArrear(arrear,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.CreateArrear(arrear)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(teacherActions.getClassArrears(tic))
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ARREAR_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ARREAR_ADD_FAILURE,error}
    }
}

function editTeacherSubjects(alteredSubjects) {
    return dispatch=> {
        dispatch(alterStudents(alteredSubjects));
    }
    function alterStudents(payload) {
        return {type: teacherConstants.ALTER_TEACHER_SUBJECTS,payload}
    }

}
function alterStudents(alteredStudents) {
    return dispatch=> {
        dispatch(alterStudents(alteredStudents));
    }
    function alterStudents(payload) {
        return {type: teacherConstants.ALTER_STUDENTS,payload}
    }

}

function validateId(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.validateId(ID)
            .then(
                student=> {
                    dispatch(success(student));
                    dispatch(alertActions.success("Success!"));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)
                    dispatch(getMiniProfile(student.id))

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_VALIDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_VALIDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_VALIDATE_FAILURE, error}
    }
}
function validateTeacherId(ID) {
    return dispatch => {
        dispatch(request());

        teacherService.validateTeacherId(ID)
            .then(
                teacher=> {
                    dispatch(success(teacher));
                    dispatch(alertActions.success("Success!"));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)
                    dispatch(getTeacherMiniProfile(teacher.id))


                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_VALIDATE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.TEACHER_VALIDATE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_VALIDATE_FAILURE, error}
    }
}

function getClubMarks() {
    return dispatch => {
        dispatch(request());

        teacherService.getClubMarks()
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_MARKS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.CLUB_MARKS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_MARKS_RETRIEVE_FAILURE, error}
    }
}
function getSportMarks() {
    return dispatch => {
        dispatch(request());

        teacherService.getSportMarks()
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_MARKS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.SPORT_MARKS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_MARKS_RETRIEVE_FAILURE, error}
    }
}

function getDepartmentStats(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getDepartmentStats(tic)
            .then(
                departments=> {
                    dispatch(success(departments));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.DEPARTMENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.DEPARTMENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.DEPARTMENT_RETRIEVE_FAILURE, error}
    }
}


    function getClassArrears(tic) {
        return dispatch => {
            dispatch(request());

            teacherService.getClassArrears(tic)
                .then(
                    students=> {
                        dispatch(success(students));
                        setTimeout(()=>{dispatch(alertActions.clear())},5000)

                    },
                    error => {
                        console.log(error.Message)
                        if(error.Message){
                            dispatch(failure(error.Messsage));
                            dispatch(alertActions.error(error.Message));
                        }else{
                            dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                            dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                        }

                        setTimeout(() => {
                            dispatch(alertActions.clear())
                        }, 5000)
                    }
                );
        };

        function request() {
            return {type:teacherConstants.ALL_ARREARS_RETRIEVE_REQUEST}
        }

        function success(payload) {
            return {type: teacherConstants.ALL_ARREARS_RETRIEVE_SUCCESS,payload}
        }

        function failure(error) {
            return {type: teacherConstants.ALL_ARREARS_RETRIEVE_FAILURE, error}
        }
    }
function getStudentArrears(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getStudentArrears(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {

                    if(error.Message){
                        dispatch(failure(error.Messsage));

                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.ARREARS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.ARREARS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.ARREARS_RETRIEVE_FAILURE, error}
    }
}


function RegisterStudents(students,cls) {

    return dispatch => {

        dispatch(request());
        teacherService.RegisterStudents(students)
            .then(
                students=> {

                    dispatch(getClassRegistration(cls));
                    dispatch(success(students.Message));
                    dispatch(alertActions.success(students.Message));

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REGISTER_STUDENT_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.REGISTER_STUDENT_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.REGISTER_STUDENT_FAILURE, error}
    }
}
function getRegisteredStudents(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getRegisteredStudents(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.REGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.REGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.REGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}

function getClassRegistrationbyTic() {
    return dispatch => {
        dispatch(request());

        teacherService.getClassRegistrationbyTic()
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}
function getClassRegistration(cls) {
    return dispatch => {
        dispatch(request());

        teacherService.getClassRegistration(cls)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}
function getUnregisteredStudents(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getUnregisteredStudents(tic)
            .then(
                students=> {
                    dispatch(success(students));
                    setTimeout(()=>{dispatch(alertActions.clear())},5000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.UNREGISTERED_STUDENT_RETRIEVE_FAILURE, error}
    }
}

function getClassStudents(classId) {
    return dispatch => {
        dispatch(request());

        teacherService.getClassStudents(classId)
            .then(
                students=> {
                    dispatch(success(students));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_RETRIEVE_FAILURE, error}
    }
}

function getTeachers() {
    return dispatch => {
        dispatch(request());

        teacherService.getTeachers()
            .then(
                teachers=> {
                    dispatch(success(teachers));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHERS_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.TEACHERS_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHERS_RETRIEVE_FAILURE, error}
    }
}
function getStudents() {
    return dispatch => {
        dispatch(request());

        teacherService.getStudents()
            .then(
                students=> {
                    dispatch(success(students));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_RETRIEVE_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_RETRIEVE_FAILURE, error}
    }
}


function getClassOptions(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getClassOptions(tic)
            .then(
                options=> {
                    dispatch(success(options));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.OPTIONS_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.OPTIONS_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.OPTIONS_FAILURE, error}
    }
}

function getOptions() {
    return dispatch => {
        dispatch(request());

        teacherService.getOptions()
            .then(
               options=> {
                    dispatch(success(options));
                   
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));           
                        dispatch(alertActions.error(error.Message));
                    }else{
                         dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.OPTIONS_REQUEST}
    }

    function success(payload) {
        return {type: teacherConstants.OPTIONS_SUCCESS,payload}
    }

    function failure(error) {
        return {type: teacherConstants.OPTIONS_FAILURE, error}
    }
}

function getTeacher(tic) {
    return dispatch => {
        dispatch(request());

        teacherService.getTeacher(tic)
            .then(
                tic=> {
                    dispatch(success(tic));

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_RETRIEVE_REQUEST}
    }

    function success(TIC) {
        return {type: teacherConstants.TEACHER_RETRIEVE_SUCCESS,TIC}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_RETRIEVE_FAILURE, error}
    }
}


function getSubjectMarks(Subject ,SubjectClass){
        return dispatch => {
        dispatch(request());

        teacherService.getSubjectMarks(Subject ,SubjectClass)
            .then(
               marks=> {
                    dispatch(success(marks));
                   
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));           
                        dispatch(alertActions.error(error.Message));
                    }else{
                         dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARKS_RETRIEVE_REQUEST}
    }

    function success(marks) {
        return {type: teacherConstants.MARKS_RETRIEVE_SUCCESS,marks}
    }

    function failure(error) {
        return {type: teacherConstants.MARKS_RETRIEVE_FAILURE, error}
    }
}
function uploadStudents(file) {
    return dispatch => {
        dispatch(request());

        teacherService.uploadStudents(file)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };
    function request() {
        return {type:teacherConstants.STUDENT_UPLOAD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_RETRIEVE_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_UPLOAD_FAILURE,error}
    }
}
function submitMarks(file,subjectKey,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.submitMarks(file,subjectKey,classKey)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSubjectMarks(subjectKey,classKey))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 8000)
                },
                error => {

                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.MARKS_SUBMIT_FAILURE,error}
    }
}



function updateStudent(student,Id) {
    return dispatch => {
        dispatch(request());

        teacherService.updateStudent(student,Id)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ADD_FAILURE,error}
    }
}
function saveStudent(student) {
    return dispatch => {
        dispatch(request());

        teacherService.saveStudent(student)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)

                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_ADD_FAILURE,error}
    }
}

function editTeacher(teacher) {
    return dispatch => {
        dispatch(request());

        teacherService.editTeacher(teacher)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getTeachers())
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)


                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.TEACHER_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_ADD_FAILURE,error}
    }
}

function saveTeacher(teacher) {
    return dispatch => {
        dispatch(request());

        teacherService.saveTeacher(teacher)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getTeachers())
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)


                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.TEACHER_ADD_REQUEST}
    }

    function success() {
        return {type: teacherConstants.TEACHER_ADD_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.TEACHER_ADD_FAILURE,error}
    }
}

function submitMark(mark,subjectKey,classKey) {
    return dispatch => {
        dispatch(request());

        teacherService.submitMark(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSubjectMarks(subjectKey,classKey))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.MARKS_SUBMIT_FAILURE,error}
    }
}

function submitClubMark(mark) {
    return dispatch => {
        dispatch(request());

        teacherService.submitClubMark(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getClubMarks())
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.CLUB_MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.CLUB_MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.CLUB_MARKS_SUBMIT_FAILURE,error}
    }
}


function submitClassTeacherComments(mark,tic) {
    return dispatch => {
        dispatch(request());

        teacherService.submitClassTeacherComments(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getStudentComments(tic))
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.STUDENT_COMMENT_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.STUDENT_COMMENT_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.STUDENT_COMMENT_SUBMIT_FAILURE,error}
    }
}

function submitSportMark(mark) {
    return dispatch => {
        dispatch(request());

        teacherService.submitSportMark(mark)
            .then(
                response=> {
                    dispatch(success());
                    dispatch(alertActions.success(response.Message));
                    dispatch(getSportMarks())
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                },
                error => {
                    console.log(error.Message)
                    if(error.Message){
                        dispatch(failure(error.Messsage));
                        dispatch(alertActions.error(error.Message));
                    }else{
                        dispatch(failure("Error encountered, Server may be temporarily down please try again later"));
                        dispatch(alertActions.error("Error encountered, Server may be temporarily down please try again later"));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 5000)
                }
            );
    };

    function request() {
        return {type:teacherConstants.SPORT_MARKS_SUBMIT_REQUEST}
    }

    function success() {
        return {type: teacherConstants.SPORT_MARKS_SUBMIT_SUCCESS}
    }

    function failure(error) {
        return {type: teacherConstants.SPORT_MARKS_SUBMIT_FAILURE,error}
    }
}


function  downloadEncodedFile(file , fileName){

    let base64Data =file

    let  contentType ='';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    var link=document.createElement('a');
    link.href=window.URL.createObjectURL(new Blob(byteArrays, ));
    link.download=fileName;
    link.click();

}

