
import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";


function InfoPopup(props) {

  //document.getElementsByClassName("modal-backdrop")[0].hidden =false;
  const popup = useSelector((state) => state.popupReducer.infoPopup);
  const popupinfo = useSelector((state) => state.popupReducer.info);

  function getFormattedTime(strt) {
    strt = new Date(strt);
    return strt.getFullYear()+"/"+strt.getMonth()+"/"+strt.getDate()+ " "+strt.getHours()+":"+strt.getHours()

}


  return (
      popup ? (
            <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center ">
          {popup ? (
            <>
              <div className="row align-items-center">


                <div className="col ">
                  <strong className="mt-4 text-bold"> {popupinfo.title}</strong>
                </div>
              </div>
            </>
          ) : (
            "Event"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {popup ? (
            <>
                <div className="row">

                  <div className="col badge badge-dark"><strong>Start: {getFormattedTime(popupinfo.start)+""}</strong></div>
                  <div className="col badge badge-dark "><strong>End: {getFormattedTime(popupinfo.end)+""}</strong></div>

                </div>
                <div className="row py-3 my-4 bg-white border">
                  <div className="col"><p className={"text-black ttb"}>{popupinfo.event.split('^')[0]}</p></div>
                </div>
                <div className="row">
                  <div className="col"><small><strong>{popupinfo.event.split('^')[1]}</strong></small></div>
                </div>



            </>
          ) : (
            <p>Nothing to show :(</p>
          )}
        </div>
      </Modal.Body>

    </Modal></>):""
  );
}
export default InfoPopup;
