import React from "react";

import { userActions } from "../actions/user.actions";
import logo from "../assets/compuLearnSource/EjohnsBadgeWhite.png";
import {popupActions} from "../actions/popup.actions";
import {useDispatch, useSelector} from "react-redux";

function Navbar() {
  const dispatch = useDispatch();
  function handleUserClick(user) {
    dispatch(popupActions.settings(user));
  }
  function toggleMenu() {
    //**** Pending Fix
    // You have to use the component's State to update
    // component parameters such as Class Name if you
    // want React to render your DOM correctly and efficiently.
    document.querySelector("body").classList.toggle("small_menu");
  }

  const loading = useSelector((state) => state.teacherReducer.loading);
  return (
    <React.Fragment>
      <nav className={"navbar navbar-fixed-top "}>
        <div className={"container-fluid"}>
          <div className={"navbar-left "}>
            <div className={"ml-3 row align-items-center"}>
              <div className="col p-0">
                <div onClick={toggleMenu} className="zimra-menu ">
                  <i className={"fa fa-bars fa-md  m-0 p-0"}></i>
                </div>
              </div>
              <div className="col">
                <img src={logo} width="100" alt="School Logo" />
              </div>
              <span>
                <strong className="zimra-title text-dark text-italic">
                  {" "}

                </strong>
              </span>
            </div>
          </div>

          <div className={"navbar-right"}>

            <div id={"navbar-menu row align-items-center"}>


              <ul className={"nav navbar-nav"}>

                <li className={""}>
                  {loading?(<>
                    <div className="loadingio-spinner-double-ring-fupwv6jvt9e">
                      <div className="ldio-9smo7gkmuaf">
                        <div></div>
                        <div></div>
                        <div>
                          <div></div>
                        </div>
                        <div>
                          <div></div>
                        </div>
                      </div>
                    </div>




                  </>):""}
                </li>
                <li>
                  <div onClick={userActions.logout} className={"icon-menu btn"}>
                    <i className={"fa fa-power-off zimra-tool"}>
                      {" "}
                      <strong className="zimra-tool">{"  LOGOUT"}</strong>
                    </i>
                  </div>
                </li>
                <li>
                  <div onClick={handleUserClick} className={"icon-menu btn"}>
                    <i className={"fa fa-user zimra-tool"}>
                      {" "}
                      <strong className="zimra-tool">
                        {" " +
                          JSON.parse(
                            localStorage.getItem("user")
                          ).username.toUpperCase()}
                      </strong>
                    </i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
export default Navbar;
